.bit-link-new-pin {
  text-align: center;
}
.bit-pin-desc {
  padding-top: 20px;
  color: #bbb;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 4px;
}
.bit-new-pin {
  display: flex;
  align-items: center;
  height: 28px;
  justify-content: center;
  height: 28px;
  margin-top: 14vw;
}

.bit-new-pin .pin-input-wrapper {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(5, 150, 105, 1);
  display: flex;
  min-height: 56px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;
}

.bit-new-pin .pin-input-wrapper.error {
  border: 1px solid rgba(220, 38, 38, 1);
}

.bit-new-pin .pin-input-wrapper .pin-input-content {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;

  position: relative;
}

.bit-new-pin .pin-input-wrapper .pin-input-content.hasToggle {
  margin-right: -38px;
}

.pin-toggle-password {
  height: 30px;
  width: 40px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 4;
}

.pin-toggle-password svg {
  width: 18px;
}

.bit-new-pin .pin-input-wrapper .pin-input-content .pin-dot {
  border: solid 1px black;
  border-radius: 100%;
  background: black;
  margin: 0;
  margin-left: 6px;
  margin-right: 6px;
  padding: 0;
  height: 12px;
  width: 12px;
  min-height: 12px;
  min-width: 12px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: black;
  caret-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.pin-dot-wrapper {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.bit-new-pin .pin-input-wrapper .pin-input-content .pin-text {
  font-size: 16px;
  margin-left: 6px;
  margin-right: 6px;
  letter-spacing: 12px;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  opacity: 1;
  padding-right: 40px;
  text-indent: 50px;
  text-align: center;
}

.bit-new-pin .pin-input-wrapper .pin-input-content .pin-text.pin-text-hide {
  opacity: 0;
}

.bit-new-pin .pin-input-wrapper .pin-input-content .pin-text::placeholder {
  letter-spacing: normal;
}

.bit-button-submit-wrapper {
  width: 100%;
  margin-bottom: 20px;
  padding-inline: 20px;
}

.bit-button-submit {
  background-color: rgba(0, 171, 107, 1);
  color: #fff;
  width: 100%;
  height: 3rem;
  font-weight: bold;
  border-radius: 6px;
  border: none;
}

.bit-button-submit:disabled {
  background-color: rgba(0, 171, 107, 0.5);
  color: #fff;
}

.bit-bottom-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 42px;
}

.bit-custom-keyboard {
  background: #fff;
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  margin-left: -20px;
  bottom: 42px;
}

.bit-bottom-wrapper > .bit-custom-keyboard {
  position: static;
  margin-left: 0;
}

.bit-custom-keyboard div {
  color: #000000;
  font-size: 28px;
  text-align: center;
  padding: 12px 0;
  width: 33.33%;
}
.bit-custom-keyboard div:nth-child(1) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to left, #e5e5e5 0%, #fff 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(2) {
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid;
  border-right: 1px solid;
  border-image: linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(3) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, #e5e5e5 0%, #fff 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(4) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to left, #e5e5e5 0%, #fff 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(5) {
  border: 0;
  border: 1px solid #e5e5e5;
  border-top: 0;
}
.bit-custom-keyboard div:nth-child(6) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, #e5e5e5 0%, #fff 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(7) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to left, #e5e5e5 0%, #fff 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(8) {
  border: 0;
  border: 1px solid #e5e5e5;
  border-top: 0;
}
.bit-custom-keyboard div:nth-child(9) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, #e5e5e5 0%, #fff 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(10) {
}
.bit-custom-keyboard div:nth-child(11) {
  border: 0;
  border-left: 1px solid;
  border-right: 1px solid;
  border-image: linear-gradient(to bottom, #e5e5e5 0%, #fff 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(12) {
}
.bit-link-new-pin .login-register-icon img {
  height: 90px;
}
@-webkit-keyframes ipt-anim {
  0% {
    background: #fff;
    border: solid 1px #fff;
    box-shadow: none;
    color: #666;
  }
  50% {
    background: #fff;
    border: solid 1px #fff;
    box-shadow: none;
    color: #666;
  }
  60% {
    background: #00ab6b;
    border: solid 1px #ededed;
    box-shadow: 0px 0px 0px 5px rgba(210, 243, 230, 1);
    color: #00ab6b;
  }
  100% {
    background: #00ab6b;
    border: solid 1px #00ab6b;
    box-shadow: 0px 0px 0px 5px rgba(210, 243, 230, 1);
    color: #00ab6b;
  }
}
@-webkit-keyframes ipt-anim-pulse {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0px 0px 0px 5px rgba(210, 243, 230, 1);
  }
}

@media (min-height: 640px) {
  .bit-new-pin {
    margin-top: 16vh;
  }
  .bit-link-new-pin .login-register-icon img {
    height: 14vh;
  }
}

@media only screen and (min-width: 1030px) {
  .bit-link-new-pin .login-register-icon img {
    height: 90px !important;
  }
}
